@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;900&display=swap');
html, body{
  background:#000;
  width: 100vw;
  height: 100vh;
  overscroll-behavior: auto; /* default */
  overscroll-behavior: contain;
  overscroll-behavior: none;
  scroll-behavior: smooth;
  color:#fff;
  font-family: 'Poppins', sans-serif;
  font-size:20px;
}
.whatsapp{
  position: fixed;
  bottom: 20px;
  left:22px;
  z-index: 888;
  height: 60px;
  width: 60px;
}
img{
  filter: contrast(1.2) hue-rotate(11deg);
  image-rendering: -webkit-optimize-contrast;
}
.social-icons a{
  margin: 10px;
}
.nav{
  position: sticky;
  z-index: 9;
  background:rgba( 0,0,0, 0.5);
  width: 100%;
  height: 100px;
  padding-top: 30px;
  top: -30px;
  transition: ease all 0.3s;
  margin-top:0px;
}
.nav > div{
  margin: 0 24px;
}
.header_bg{
  background: url("./assets/bg_pcc.png");
  z-index: 1;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-size: cover;
  background-position: 55% center;
  background-repeat: no-repeat;
  filter: contrast(1.05);
  overflow: hidden;
  top: 0;
  animation: zoom-in 60s infinite;
}
.parallax-bg{
  position: relative;
  z-index: 2;
  margin-top:calc(100vh - 100px);
  background: #000;
  color:#fff;
  min-height:calc(100vh);
}
#contact, #works, #impressum, #datenschutz{
  padding-top:60px;
}
.parallax-bg img{
  max-height:80vh;
  object-fit: cover;
  object-position: bottom;
}
.parallax-bg-w{
  position: relative;
  z-index: 2;
  background: #fff;
  color: #000;
}
.react-photo-gallery--gallery img:hover{
  transform: scale(1.03);
  transition: ease all .3s;
  filter:blur(1.2);
}
.logo{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30vh;
  height: 30vh;
  z-index: 2;
}
.nav-menu{
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
}
.nav-menu li a{
  color: #fff;
  margin-right:12px;
  font-size:18px;
  font-weight: 300;
  opacity:.7;
  transition: ease all .3s;
}
.nav-menu li.active a{
  margin-right:12px;
  font-size:18px;
  font-weight: 700;
  opacity:1;
  transition: ease all .3s;
}
.nav-menu li a{
  text-decoration: none;
  transition: ease all .3s;
}
.nav-footer{
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
}
.nav-footer li a{
  color: #fff;
  margin-right:12px;
  font-size:18px;
  font-weight: 300;
  opacity:.7;
  transition: ease all .3s;
}
.nav-footer li.active a{
  margin-right:12px;
  font-size:18px;
  font-weight: 700;
  opacity:1;
  transition: ease all .3s;
}
.nav-footer li a{
  text-decoration: none;
  transition: ease all .3s;
}
.menu{
  width: 40px;
  height: 30px;
  position: relative;
  cursor: pointer;
  z-index: 888;
  display: none;
}

.menu span{
  position: absolute;
  height: 3px;
  width: 100%;
  border-radius: 6px;
  background: #fff;
}
.menu span:nth-child(1){
  top: 0;
}
.menu span:nth-child(2){
  top: 50%;
  transform: translate(0 ,-50%);
}
.menu span:nth-child(3){
  bottom: 0;
}
#datenschutz, #impressum{
  hyphens: auto;
}
.css-16jr3vd {
  background-color: rgba(0, 0, 0, .92)!important;
}
.special_bg{
  background: rgba(207, 194, 151, .3);
  overflow: hidden;
  width: 100%;
  min-height: 400px;
}
.pswp{
  z-index: 999!important;
}
h3 {
  font-weight: 700;
  font-size: 1.7rem;
  text-transform: uppercase;
}
h2 {
  font-weight: 700;
  font-size: 1.9rem;
  text-transform: uppercase;
}
p{
  font-weight: 300;
}
.service h4 {
  font-weight: bold;
  font-size: 1.1rem;
  text-transform: uppercase;
}
.service img{
  object-fit: cover;
  border-radius: 100%;
  max-width: 100%;
  margin: 46px;
  width: 250px;
  height: 250px;
}
.footer{
  min-height:100px;
  width: 100%;
  background: #000;
  position: relative;
  z-index: 222;
}
.footer a{
  text-decoration: none;
  color: #fff;
}

.smoke {
opacity: 0.45;
filter: brightness(5) grayscale(1);
z-index : 0;
mix-blend-mode : screen;
}
/*MOBILE*/
@media screen and (max-width:768px) {
  .nav-menu{
    display: none;
  }
  .menu{
    display: block;
  }
}

/* ANIMATIONS*/

@keyframes zoom-in {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
  }
  } 
